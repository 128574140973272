import request from '@/utils/request'

/**
 * 筛选软件信息
 * @param {Object} data
 * @returns
 */
export function getList(data) {
  return request({
    url: '/api/SoftException/GetList',
    method: 'post',
    data,
  })
}

// 清空日志
export function piceDelete(data){
  return request({
    url: '/api/SoftException/PiceDelete',
    method: 'get',
    data,
  })
}

// 更具日志ID删除日志
export function deleteById(id){
  return request({
    url: '/api/SoftException/DeleteById',
    method:'get',
    params:{id}
  })
}

export function uploadLog(data) {
  return request({
    url: '/api/SoftException/UploadLog',
    method: 'post',
    data,
  })
}

export function checkLog(data) {
  return request({
    url: '/api/SoftException/CheckLog',
    method: 'post',
    data,
  })
}

// GetSoftExceptionOption
export function getSoftExceptionOption() {
  return request({
    url: '/api/SoftException/GetSoftExceptionOption',
    method: 'get',
  })
}
